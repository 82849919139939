

// translated into SASS from clients code

$tertiary-background-color: #B70062;
$color4: #68819C;  // light blue
$color5: #C42626; // deek orange
$color7: #525e66; // blue grey
$color1: #58709a;
$color2: #6F3092;  // African Violet Light
$color3: #ED2482;  // Mulberry Light
$color4: #68819C;  // light blue
$color5: #C42626; // dark orange
$color6: #2D3E50; // dark blue
$color7: #525e66; // blue grey

.header .header-details {
    right: 10px;
    top: 20px;
}

.heading-primary, 
.bg-primary, 
.content-block.bg-primary, 
.panel-default .panel-heading, 
.panel-primary .panel-heading, 
.label-primary {
    background-color: #330066;
    border-color: $color6;
    color: #FFF;
}

.heading-info, 
.bg-info, 
.content-block.bg-info, 
.panel-info .panel-heading, 
.label-info {
    background-color: $color6;
    border-color: $color6;
    color: #FFF;
}

.nav-tabs {
    li {
        &.active {
            .btn-tab {
                background-color: $color5;
                color: #FFF;
                border: 1px solid $color5;
            }
        }

        .btn-tab {
            &:hover,
            &:focus {
                background-color: $color5;
                color: #FFF;
                border: 1px solid $color5;
            }
        }

    }
}

.heading, .panel-heading {
    color: #FFF;
    background-color: $color6;
    padding: 8px;
    border-bottom: 2px solid $color6;
    margin-top: 0px;
}

.heading-light a, .panel-light .panel-heading {
    color: #fff;
}

.panel-light .panel-heading {
    background:$color6;
}

.table > thead > tr > th {
    background-color: $color4;
}

.table > caption + thead > tr:first-child > th, 
.table > colgroup + thead > tr:first-child > th, 
.table > thead:first-child > tr:first-child > th, 
.table > caption + thead > tr:first-child > td, 
.table > colgroup + thead > tr:first-child > td, 
.table > thead:first-child > tr:first-child > td {
    border: 1px solid $color4;
}

.table-header {
    border-bottom: 2px solid $color4;
    margin-bottom: 1px;
    background-color: $color4;
}

.btn-primary {
    background-color: $color4 !important;
    &:hover,
    &:active,
    &:focus,
    .open > .dropdown-toggle.btn-primary {
        background-color: $color4 !important;
        border-color: $color4;
    }
}

.table-footer {
    .pagination {
        li {
            &.active {
                a,
                span {
                    background-color: $color4;
                    border-color: $color4;
                    color: #FFF;
                    &:hover,
                    &:focus {
                        background-color: $color4;
                        border-color: $color4;
                        color: #FFF;
                    }
                }
            }
        }
    }
}

.input-animation-slide-right {
    border-color: #cbcece;

    label {
        background: #ffffff;
        color: $color7;
    }
}

.footer-container {
    footer {
        background: $color7;  

        .version {
            padding-left: 15px;
        }
    }
}

.mobile-user-details {
    .sign-out {
        background: $tertiary-background-color;
        &:hover {
            color: #fff;
        }
    }
}